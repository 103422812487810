export const COOKIE_SEPARATOR = ';';
export const PAIR_SEPARATOR = '&';
export const KEY_VALUE_SEPARATOR = ':::';

export const COOKIES = Object.freeze({
  SCANNER: 'scanner',
  CULTURE: 'ssculture',
  AB: 'ssab',
  AB_OVERRIDES: 'ssaboverrides',
  AB_GROUP: 'abgroup',
  TRAVELLER_CONTEXT: 'traveller_context',
  ANONYMOUS_TOKEN: '__Secure-anon_token',
  ANONYMOUS_CSRF_TOKEN: '__Secure-anon_csrf_token',
  SID_TOKEN: 'sid_token',
  SID_UTID: 'sid_utid',
  SID_SESSION_TOKEN: 'sid_session_token',
  GDPR: 'gdpr',
  HAS_APP: 'has_app',
  DEVICE_GUID: 'device_guid',
  SECURE_SKA: '__Secure-ska',
  EXPERIMENT_ALLOCATION_ID: 'experiment_allocation_id',
});
