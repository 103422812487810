export const getViewName = (action) => {
  if (((action || '').match(/dayview/gi) || []).length) {
    return 'Day View';
  }

  if (((action || '').match(/monthview/gi) || []).length) {
    return 'Month View (Chart)';
  }

  if (((action || '').match(/browse/gi) || []).length) {
    return 'Browse View (Day)';
  }

  if (((action || '').match(/index/gi) || []).length) {
    return 'Homepage';
  }

  if (((action || '').match(/skippy/gi) || []).length) {
    return 'Skippy';
  }
  return action;
};
