import type { ElementCommon } from '@skyscanner-internal/schemas-types/user_behaviour_data_domain.pb';

// Take camelCase string Types and translate them into snake_case.
type camelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${camelToSnakeCase<U>}`
  : S;

// Strip out grappler types from the schema which we don't send.
type RemoveGrapplerAttributes<Type> = {
  [Property in keyof Omit<
    Type,
    'header' | 'grapplerReceiveTimestamp'
  >]: Type[Property];
};

// This takes a schemas type which needs to be transformed back to the expected schema by the API.
// Transforms all keys back to snake_case as defined in the schemas. This replaces the types on the top level.
// Re-calls the recursiveFunction for ElementCommon to transform those keys into snake_case.
type TransformToProtoRecursive<Type, Common = never> = {
  [Key in keyof Type as camelToSnakeCase<
    string & Key
  >]: ElementCommon extends Type[Key] ? Common : Type[Key];
};

// This helper allows us to see resolved type directly without displaying the utility type's composition.
// https://stackoverflow.com/questions/57683303/how-can-i-see-the-full-expanded-contract-of-a-typescript-type
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

// Strips unnecessary type attributes using removeGrapplerAttributes once.
type TransformFromProto<T, C> = TransformToProtoRecursive<
  RemoveGrapplerAttributes<T>,
  C
>;

type MakeKeysRequired<T, K extends keyof T> = Expand<
  Omit<T, K> & Required<Pick<T, K>>
>;

export const isDevEnv = () => window.location.hostname === 'localhost';

export type { TransformFromProto, MakeKeysRequired };
