import * as constants from './constants';

const pair = (key, value) =>
  !!value || value === false
    ? `${key}${constants.KEY_VALUE_SEPARATOR}${value}`
    : key;
const combine = (a, b) => (a ? `${a}${constants.PAIR_SEPARATOR}${b}` : b);

export default function serializeToString(object) {
  if (!object) {
    return '';
  }

  if (typeof object === 'string') {
    return object;
  }

  const keys = Object.keys(object);
  if (keys.length === 0) {
    return '';
  }

  return keys.map((key) => pair(key, object[key])).reduce(combine);
}
