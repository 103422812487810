import debounce from 'lodash.debounce';
import { SENDBEACON_MECHANISM } from 'saddlebag-grappler';
import { logOperationalEvent } from 'saddlebag-logger';

import { BehaviouralTracker } from '../BehaviouralTracker';

import {
  getProcessedImpressionElement,
  impressionProcessor,
} from './Impression/impressionProcessor';
import { InteractionMessage } from './message';
import { getInputValue, setCommonProperties } from './utils';

import type { MBDEvent } from './Event';
import type { GUID } from '../pageGuid';
import type { ElementInteractionType } from '@skyscanner-internal/schemas-types/user_behaviour_data_domain.pb';

const sendInteraction = (
  impressionGuid: GUID,
  value: string | boolean | number | undefined = undefined,
) => {
  const interactionMessage = new InteractionMessage({
    element_impression_guid: impressionGuid,
    ...(value && { common: setCommonProperties(value) }),
    interaction_type: 'CLICK' as ElementInteractionType.CLICK,
  });
  BehaviouralTracker.track(
    'element_interaction',
    'user_behaviour_data_domain.ElementInteraction',
    interactionMessage,
    SENDBEACON_MECHANISM,
  );
};

const IMPRESSION_PROCESSOR_DEBOUNCE_WAIT_MS = 500;
const debouncedImpressionProcessor = debounce(
  impressionProcessor,
  IMPRESSION_PROCESSOR_DEBOUNCE_WAIT_MS,
  {
    leading: true,
  },
);

const click = (event: MouseEvent) => {
  // force impression callback on mouse events. (incase there are some waiting to be sent)
  debouncedImpressionProcessor(
    document.querySelectorAll('[data-tracking-element-id]'),
  );
  // we don't want to send the default click events for our form elements. We will send the event on change.
  if ((event.target as Element)?.matches('input,select,option,textarea')) {
    return;
  }

  // Only targetting Elements, ref: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
  if (event.target instanceof Element) {
    // Find the closest matching parent or itself.
    const closestElement = event.target.closest('[data-tracking-element-id]');
    // Nothing matched
    if (closestElement === null) return;

    const id = closestElement.getAttribute('data-tracking-element-id');
    if (id) {
      const impression = getProcessedImpressionElement(id);
      if (impression && impression.guid) {
        sendInteraction(impression.guid);
      } else {
        logOperationalEvent({
          eventName: 'Interaction_click_missing_impression',
        });
      }
    }
  }
};

const change = (event: Event) => {
  if (
    (event.target as Element)?.matches(
      'input[data-tracking-element-id],select[data-tracking-element-id],textarea[data-tracking-element-id',
    )
  ) {
    // Only targetting Elements, ref: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLSelectElement
    ) {
      const id = event.target.getAttribute('data-tracking-element-id');
      if (id) {
        const impression = getProcessedImpressionElement(id);
        if (impression && impression.guid) {
          const value = getInputValue(event.target);
          sendInteraction(impression.guid, value);
        } else {
          logOperationalEvent({
            eventName: 'Interaction_change_missing_impression',
          });
        }
      }
    }
  }
};

export const Interaction: MBDEvent = {
  init: () => {
    document.addEventListener('click', click, { capture: true });
    document.addEventListener('change', change, { capture: true });
  },
  startPageTransition: () => {}, // noop, currently not used
  stopPageTransition: () => {}, // noop, currently not used
};
