import {
  clearAllProcessedImpressionElements,
  impressionProcessor,
} from './Impression/impressionProcessor';

import type { MBDEvent } from './Event';

// Timeout Interval instance we use for querying the dom
let pageDomScanner: ReturnType<typeof setTimeout>;
const delay = 1000; // to poll every second

/**
 * A DOM Scanner used for querying the DOM in set intervals.
 * On each interval it will query the document passing the results to `impressionProcessor`.
 * It then sets a timeout to recusively call itself to scan the DOM after the next delay.
 *
 * @remarks
 * We prefer setTimeout over setInterval as this ensures that timer events don't "stack" if they're left unprocessed.
 * In some circumstances a whole load of setInterval events can arrive immediately after each other without any delay.
 * Whereas with setTimeout the queue will only be pushed to once the previous job has finished, avoiding the stacking problem.
 *
 * @returns
 */

const domScanner = () => {
  impressionProcessor(document.querySelectorAll('[data-tracking-element-id]'));
  pageDomScanner = setTimeout(domScanner, delay); // recursively scan the DOM after delay
};

export const Impression: MBDEvent = {
  init: () => {},
  startPageTransition: () => {
    clearTimeout(pageDomScanner);
  },
  stopPageTransition: () => {
    clearAllProcessedImpressionElements();
    if (document.body) {
      pageDomScanner = setTimeout(domScanner, delay);
    }
  },
};
