import { FETCH_MECHANISM, SENDBEACON_MECHANISM } from 'saddlebag-grappler';
import { MiniEventsTracking } from 'saddlebag-user-tracking-events';

import type { EventConfiguration } from './configuration';
/**
 * This is a Singleton class for initalising an instance of MiniEventsTracking and accessing it.
 */

export class MiniEventsTrackingSingleton {
  private static fetch: MiniEventsTracking;

  private static beacon: MiniEventsTracking;

  /**
   * The constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  // eslint-disable-next-line no-empty-function
  private constructor() {}

  public static init(configuration: EventConfiguration): void {
    MiniEventsTrackingSingleton.fetch = new MiniEventsTracking(
      configuration.environment,
      'web_mini',
      {
        enhance: false, // enhance needs to be defined otherwise grappler will default to true.
        mechanism: FETCH_MECHANISM,
        ...(configuration.behavioural.batch
          ? {
              batching: {
                enabled: configuration.behavioural.batch.impression,
                flushAfter: 1, // we don't need to wait as the impressionProcessor won't trigger for 1000ms
                maxBufferSize: 100000, // default
                flushOnExit: true,
              },
            }
          : {}),
      },
    );
    MiniEventsTrackingSingleton.beacon = new MiniEventsTracking(
      configuration.environment,
      'web_mini',
      {
        enhance: false, // enhance needs to be defined otherwise grappler will default to true.
        mechanism: SENDBEACON_MECHANISM,
        ...(configuration.behavioural.batch
          ? {
              batching: {
                enabled: configuration.behavioural.batch.interaction,
                flushAfter: 200, // default
                maxBufferSize: 30000, // sendBeacon is limited to 64KB on some browsers
                flushOnExit: false,
              },
            }
          : {}),
      },
    );
  }

  public static getFetchInstance(): MiniEventsTracking {
    if (!MiniEventsTrackingSingleton.fetch) {
      throw new Error('MiniEventsTrackingSingleton not initialised!');
    }

    return MiniEventsTrackingSingleton.fetch;
  }

  public static getBeaconInstance(): MiniEventsTracking {
    if (!MiniEventsTrackingSingleton.beacon) {
      throw new Error('MiniEventsTrackingSingleton not initialised!');
    }

    return MiniEventsTrackingSingleton.beacon;
  }
}
