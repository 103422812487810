import { FETCH_MECHANISM } from 'saddlebag-grappler';
import { logOperationalEvent } from 'saddlebag-logger';

import { MiniEventsTrackingSingleton } from './MiniEventsTrackingSingleton';
import { ValidationError } from './events/validationError';
import { isDevEnv } from './utils';

import type { EventMessage, EventName } from './types/message';
import type { SENDBEACON_MECHANISM } from 'saddlebag-grappler';
import type { Message } from 'saddlebag-user-tracking-events';

export const BehaviouralTracker = {
  // Track method to get instance and send event
  track: async function track<M extends EventMessage>(
    eventName: EventName,
    fullSchemaName: string,
    message: M,
    emission: typeof FETCH_MECHANISM | typeof SENDBEACON_MECHANISM,
    opts?: Record<string, string | undefined>,
  ): Promise<Message> {
    try {
      message.validate();
    } catch (e: any) {
      if (isDevEnv()) {
        console.error(e);
      }
      logOperationalEvent({
        message: e.message,
        eventName:
          e instanceof ValidationError
            ? 'BehaviouralTracker_Validation'
            : 'BehaviouralTracker_Error',
      });
      return Promise.reject(e);
    }
    // defined list of events that should be send with beacon.
    if (emission === FETCH_MECHANISM) {
      const miniEventsTracker = MiniEventsTrackingSingleton.getFetchInstance();
      return miniEventsTracker.track(
        eventName,
        fullSchemaName,
        message.data,
        opts,
      );
    }
    const miniEventsTracker = MiniEventsTrackingSingleton.getBeaconInstance();
    return miniEventsTracker.track(
      eventName,
      fullSchemaName,
      message.data,
      opts,
    );
  },
};
