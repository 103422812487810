const UNSET_PLATFORM = 0;
const BROWSER_DESKTOP = 1;
const BROWSER_IOS_PHONE = 2;
const BROWSER_IOS_TABLET = 3;
const BROWSER_ANDROID_PHONE = 4;
const BROWSER_ANDROID_TABLET = 5;
const BROWSER_MOBILE_PHONE = 6;
const BROWSER_MOBILE_TABLET = 7;

const platformOptions = {
  ios: { mobile: BROWSER_IOS_PHONE, tablet: BROWSER_IOS_TABLET },
  android: { mobile: BROWSER_ANDROID_PHONE, tablet: BROWSER_ANDROID_TABLET },
  other: { mobile: BROWSER_MOBILE_PHONE, tablet: BROWSER_MOBILE_TABLET },
};

/**
 * Encode platform contraints
 * @param {object} data - Data passed from data attribute, enriched with user info
 * @returns {number} Platform enum value
 */
const getPlatform = (data) => {
  const device = data.device || {};
  const osName = device.osName || '';

  if (!device.isBrowser && !device.isMobilePhone && !device.isTablet) {
    return UNSET_PLATFORM;
  }

  if (device.isBrowser) {
    return BROWSER_DESKTOP;
  }

  const key = device.isTablet ? 'tablet' : 'mobile';
  switch (osName) {
    case 'iOS':
      return platformOptions.ios[key];
    case 'iPadOS':
      return platformOptions.ios[key];
    case 'Android':
      return platformOptions.android[key];
    default:
      return platformOptions.other[key];
  }
};

/**
 * Encode Skyscanner specific contraints
 * @param {object} data - Data passed from data attribute, enriched with user info
 * @returns {number} Encoded skyscanner modifier
 */
const getSkyscannerModifiers = (data) => {
  const device = data.device || {};
  const isInternalUser = data.userInfo.isInternalUser === true;
  const isLoggedIn = data.userInfo.isLoggedIn === true;
  const isRobot = device.isRobot === true;

  const binaryString = [
    Number(isInternalUser),
    Number(isLoggedIn),
    Number(isRobot),
  ].join('');

  return parseInt(binaryString, 2);
};

export { getPlatform, getSkyscannerModifiers };
