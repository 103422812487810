import { ValidationError } from './validationError';

import type { Impression, Interaction, Exit, Common } from '../types/message';

type FlattenedProperties = { [key: string]: string | number | boolean } | {};

const getFlattenedProperties = (common: Partial<Common>) => {
  const commonKeys = Object.keys(common) as Array<keyof Common>;
  return commonKeys.reduce<FlattenedProperties>(
    (acc: FlattenedProperties, current) => {
      let flatten;
      const commonValue = common[current];
      if (typeof commonValue === 'object') {
        flatten = { ...acc, ...commonValue };
      } else {
        flatten = acc;
      }
      return flatten;
    },
    {},
  );
};

const assertCommonPropertiesSize = (common: Partial<Common>) => {
  const flattenProperties = getFlattenedProperties(common);
  if (Object.keys(flattenProperties).length > 20) {
    throw new ValidationError(
      `Too many properties assigned to element ${common?.component_name} please keep it to a maximum of 20`,
    );
  }
};

const assertComponentNameSet = (common: Common) => {
  if (Object.keys(common).indexOf('component_name') < 0) {
    throw new ValidationError('component_name missing in common object');
  }
};

const assertStringKeyLength = (propertyKey: string) => {
  if (propertyKey.length > 40) {
    throw new ValidationError(
      `${propertyKey} has length greater than 40 characters`,
    );
  }
};

const assertStringPropertyLength = (
  propertyValue: string,
  propertyKey: string,
) => {
  if (typeof propertyValue === 'string') {
    if (propertyValue.length > 40) {
      throw new ValidationError(
        `${propertyKey} has a value length greater than 40 characters`,
      );
    }
  } else {
    throw new ValidationError(
      `${propertyKey} value '${propertyValue}' is not a valid string type`,
    );
  }
};

const assertNumberPropertySize = (
  propertyValue: number,
  propertyKey: string,
) => {
  if (typeof propertyValue === 'number') {
    if (propertyValue > 4294967295 || propertyValue < -4294967295) {
      throw new ValidationError(
        `${propertyKey} value is larger than uint32 type`,
      );
    }
  } else {
    throw new ValidationError(
      `${propertyKey} value '${propertyValue}' is not a valid number type`,
    );
  }
};

const assertBooleanProperty = (propertyValue: number, propertyKey: string) => {
  if (typeof propertyValue !== 'boolean') {
    throw new ValidationError(
      `${propertyKey} value '${propertyValue}' is not a valid boolean type`,
    );
  }
};

const assertCommonIsObject = (common: Partial<Common>) => {
  if (typeof common !== 'object') {
    throw new ValidationError('common is not an object');
  }
};

const assertCommonPropertiesAreValid = (common: Partial<Common>) => {
  for (const [commonKey, commonValue] of Object.entries(common)) {
    if (typeof commonValue === 'string') {
      assertStringPropertyLength(commonValue, 'component_name');
    } else {
      for (const [propertyKey, propertyValue] of Object.entries(commonValue)) {
        assertStringKeyLength(propertyKey);
        if (commonKey === 'string_properties') {
          assertStringPropertyLength(propertyValue, propertyKey);
        }
        if (commonKey === 'numeric_properties') {
          assertNumberPropertySize(propertyValue, propertyKey);
        }
        if (commonKey === 'boolean_properties') {
          assertBooleanProperty(propertyValue, propertyKey);
        }
      }
    }
  }
};

// Deduplication function
const dedupeCommonProperties = (common: Common): Common => {
  // conditionally match the existing common object
  const dedupedCommon: Common = {
    component_name: common.component_name,
  };

  const seenKeys = new Set();

  // Iterate over the keys in properties and process them
  (Object.keys(common) as Array<keyof Common>).forEach(
    (propertyType: keyof typeof common) => {
      if (propertyType !== 'component_name') {
        const properties = common[propertyType];

        if (properties) {
          Object.keys(properties).forEach((key) => {
            // if the key is already in seenKeys we do NOT want it to be added to the dedupedCommon object
            if (!seenKeys.has(key)) {
              // Type narrowing
              if (propertyType === 'numeric_properties') {
                dedupedCommon.numeric_properties = {
                  ...(dedupedCommon.numeric_properties
                    ? dedupedCommon.numeric_properties
                    : {}),
                  [key]: properties[key] as number,
                };
              } else if (propertyType === 'string_properties') {
                dedupedCommon.string_properties = {
                  ...(dedupedCommon.string_properties
                    ? dedupedCommon.string_properties
                    : {}),
                  [key]: properties[key] as string,
                };
              } else if (propertyType === 'boolean_properties') {
                dedupedCommon.boolean_properties = {
                  ...(dedupedCommon.boolean_properties
                    ? dedupedCommon.boolean_properties
                    : {}),
                  [key]: properties[key] as boolean,
                };
              }
              seenKeys.add(key);
            }
          });
        }
      }
    },
  );
  return dedupedCommon;
};

class ExitMessage implements Exit {
  _data: Exit['data'];

  constructor(data: Exit['data']) {
    this._data = data;
  }

  get data(): Exit['data'] {
    return this._data as Exit['data'];
  }

  set data(data: Exit['data']) {
    this._data = data;
  }

  validate() {
    // We will use this in the future
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const message = this._data;
    return true;
  }
}

class ImpressionMessage implements Impression {
  _data: Impression['data'];

  constructor(data: Impression['data']) {
    this._data = data;
  }

  get data(): Impression['data'] {
    return this._data as Impression['data'];
  }

  set data(data: Impression['data']) {
    this._data = data;
  }

  validate() {
    if (this._data && 'common' in this._data && this._data.common) {
      assertCommonIsObject(this._data.common);
      assertComponentNameSet(this._data.common);
      this._data.common = dedupeCommonProperties(this._data.common);
      assertCommonPropertiesSize(this._data.common);
      assertCommonPropertiesAreValid(this._data.common);
    }
    return true;
  }
}

class InteractionMessage implements Interaction {
  _data: Interaction['data'];

  constructor(data: Interaction['data']) {
    this._data = data;
  }

  get data(): Interaction['data'] {
    return this._data as Interaction['data'];
  }

  validate() {
    if (this._data && 'common' in this._data && this._data.common) {
      assertCommonIsObject(this._data.common);
      assertCommonPropertiesSize(this._data.common);
      assertCommonPropertiesAreValid(this._data.common);
    }
    return true;
  }
}

export { ExitMessage, InteractionMessage, ImpressionMessage };
