import * as constants from '../constants';

const findCookie = (name, allCookies) => {
  const validCookies = allCookies
    .map((cookie) => {
      const [key, value] = cookie.split('=');
      return { key, value };
    })
    .filter((cookie) => cookie.key === name && cookie.value !== null);

  return validCookies.length > 0 ? validCookies[0].value : null;
};

const parseCookie = (name, cookiesString) => {
  if (!name || !cookiesString) return null;
  const trimmedCookies = cookiesString
    .split(constants.COOKIE_SEPARATOR)
    .map((e) => e.trim());
  return findCookie(name, trimmedCookies);
};

export default parseCookie;
