import { ValidationError } from './validationError';

import type { Common } from '../types/message';

type HTMLFormElementTypes =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

const validateTypes = (
  obj: {} | undefined,
  typeCheck: (value: any) => boolean,
) => {
  // If obj is undefined this means that there are no keys for us to check and this is marked as valid.
  if (obj === undefined) return true;
  // Get the values of the object that we are interested in and check each type corresponds to the type check that has been passed.
  return Object.values(obj).every(typeCheck);
};

const validateParams = (obj: Common) => {
  if (
    typeof obj.component_name !== 'string' ||
    (typeof obj.component_name === 'string' && obj.component_name.length === 0)
  ) {
    return false;
  }
  // eslint-disable-next-line camelcase
  const { boolean_properties, numeric_properties, string_properties } = obj;
  return (
    validateTypes(boolean_properties, (value) => typeof value === 'boolean') &&
    validateTypes(string_properties, (value) => typeof value === 'string') &&
    validateTypes(numeric_properties, (value) => typeof value === 'number')
  );
};

const throwValidation = (element: Element, rawParams: string | null) => {
  throw new ValidationError(
    `Error parsing data-tracking-common-params for element: ${element.getAttribute(
      'data-tracking-element-id',
    )}. Parsing: ${rawParams}`,
  );
};

function stripEmptyProperties<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, properties]) => Object.keys(properties).length !== 0,
    ),
  ) as T;
}

const isBool = (v: any) => typeof v === 'boolean';
const isString = (v: any) => typeof v === 'string';
const isNumber = (v: any) => typeof v === 'number';
const assignFilter = (
  obj: Object,
  filter: Function,
  value: boolean | number | string,
) => (filter(value) ? { ...obj, value } : obj);

export const getInputValue = (
  element: HTMLFormElementTypes,
): string | boolean | number | undefined => {
  if (element instanceof HTMLInputElement) {
    if (element.type === 'checkbox' || element.type === 'radio') {
      return element.checked;
    }
    if (element.type === 'number' || element.type === 'range') {
      return element.valueAsNumber;
    }
  }

  // prefer using FormData over raw element value
  if (element.form) {
    const data = new FormData(element.form as HTMLFormElement);
    const value = Object.fromEntries(data.entries())[element.name] as
      | string
      | boolean
      | undefined;
    return value;
  }
  return element.value;
};

/**
 * setCommonProperties is a helper function that takes a value and returns a partial Common object.
 * It's purpose is to create a Common object with a single property. Helpful for interaction events,
 * where a single property has been changed.
 * @param value
 * @returns Common
 */
export const setCommonProperties = (
  value: boolean | string | number,
): Partial<Common> => ({
  ...(typeof value === 'boolean' && { boolean_properties: { value } }),
  ...(typeof value === 'string' && { string_properties: { value } }),
  ...(typeof value === 'number' && { numeric_properties: { value } }),
});

/**
 * buildCommonObject is a helper function that takes an element and returns a Common object.
 * The element is expected to have a data-tracking-common-params attribute. This object
 * will contain all parameters defined in the data-tracking-common-params attribute.
 * @param element
 * @returns Common
 */
export const buildCommonObject = (element: Element): Common => {
  const rawParams = element.getAttribute('data-tracking-common-params');
  // If this function is called, data-tracking-common-params are expected to be defined.
  if (rawParams === null) {
    return throwValidation(element, rawParams);
  }
  try {
    let customParams = JSON.parse(rawParams) as Common;
    if (!validateParams(customParams)) {
      return throwValidation(element, rawParams);
    }
    if (
      element instanceof HTMLInputElement ||
      element instanceof HTMLSelectElement ||
      element instanceof HTMLTextAreaElement
    ) {
      const value = getInputValue(element);

      if (value !== undefined) {
        const booleanProperties = assignFilter(
          customParams.boolean_properties || {},
          isBool,
          value,
        ) as Common['boolean_properties'];
        const stringProperties = assignFilter(
          customParams.string_properties || {},
          isString,
          value,
        ) as Common['string_properties'];
        const numericProperties = assignFilter(
          customParams.numeric_properties || {},
          isNumber,
          value,
        ) as Common['numeric_properties'];
        customParams = {
          ...customParams,
          boolean_properties: booleanProperties,
          string_properties: stringProperties,
          numeric_properties: numericProperties,
        };
      }
    }
    return stripEmptyProperties(customParams);
  } catch (e) {
    return throwValidation(element, rawParams);
  }
};
