import debounce from 'lodash.debounce';
import { SENDBEACON_MECHANISM } from 'saddlebag-grappler';

import { BehaviouralTracker } from '../BehaviouralTracker';
import { getPageGuid, setPageGuid } from '../pageGuid';

import { ExitMessage } from './message';

import type { MBDEvent } from './Event';
import type { GUID } from '../pageGuid';

export const exit = ({ currentPageGuid }: { currentPageGuid?: GUID }) => {
  BehaviouralTracker.track(
    'exit',
    'exit.Exit',
    new ExitMessage({ view_guid: currentPageGuid }),
    SENDBEACON_MECHANISM,
  );
};

// Callback for visibilitychange eventListener
const visibilitychange = (_event: Event) => {
  if (document.visibilityState === 'hidden') {
    const currentPageGuid = getPageGuid();
    exit({ currentPageGuid });
  }
};

export const Exit: MBDEvent = {
  init: () => {
    /**
     * If more than one visibilitychange event fires within a second, only a single call to visibilitychange fn will be made.
     * This functionality is primarily added to limit event sending during heavy tab switching behaviour
     */
    const VISIBILITY_CHANGE_DEBOUNCE_WAIT_MS = 500;
    document.addEventListener(
      'visibilitychange',
      debounce(visibilitychange, VISIBILITY_CHANGE_DEBOUNCE_WAIT_MS, {
        leading: true,
      }),
      {
        capture: true,
      },
    );
  },
  startPageTransition: () => {
    // Expected to be called _before_ page guid has been updated.
    const currentPageGuid = getPageGuid();
    // If currentPageGuid is undefined, it is the first onPageShown & don't send an exit event
    if (currentPageGuid) {
      exit({ currentPageGuid });
    }
    setPageGuid(undefined as unknown as string);
  },
  stopPageTransition: () => {},
};
