import requestContextParameters from '@skyscanner-internal/request-context-parameters';
import { GLOBAL } from 'saddlebag-browser';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';

import { init, stopPageTransition } from '../../core/src/modern/index';

import {
  sendFunnelViewEvent,
  sendFunnelAcquisitionEvent,
  sendExperimentationAllocationEvent,
  sendExperimentUserContextEvent,
  sendUserPreferencesEvent,
} from './funnelEvents';
import { getTrackIdentifier } from './getTrackIdentifier';
import { getUserInfo } from './getUserInfo';
import { sendViewEvent } from './grappler';
import { initAsync as grapplerAsync } from './grappler/grappler';
import { setPreferences } from './setPreferences';

const doc = GLOBAL.getDocument();
const trackingScript = doc.getElementById('trackolding-sync');
const data = JSON.parse(trackingScript.getAttribute('data-data'));

const bootstrapTracking = () => {
  setPreferences();
  const { isUserNew } = data[requestContextParameters.USER_INFO];
  return isUserNew;
};

try {
  init({
    environment: 'public',
    behavioural: data.config.behavioural,
  });
} catch (error) {
  logger.logError(error);
}

try {
  const $window = GLOBAL.getWindow();
  if (!$window.__trackolding) $window.__trackolding = {};
  if (!$window.__trackolding.getTrackIdentifier)
    $window.__trackolding.getTrackIdentifier = getTrackIdentifier;
  $window.__trackolding.deploy = {
    colour: data.deployColour || 'unknown',
    region: data.deployRegion || 'unknown',
  };

  const isNewUser = bootstrapTracking();

  getUserInfo(data, $window)
    .then((userInfo) => {
      data.userInfo = { ...data.userInfo, ...userInfo };

      Object.assign(data, { isNewUser });
      sendViewEvent(data).then(() => {
        if ($window?.__internal?.screenViewEventGuid) {
          stopPageTransition($window.__internal.screenViewEventGuid);
        }
      });
      sendFunnelViewEvent(data);
      sendFunnelAcquisitionEvent(data);
      sendExperimentationAllocationEvent(data);
      sendExperimentUserContextEvent(data);
      sendUserPreferencesEvent(data);
      observer.subscribe('tracking-preferences-changed', () =>
        sendUserPreferencesEvent(data),
      );
      observer.subscribe('user-cookie-preferences-saved', () =>
        sendUserPreferencesEvent(data),
      );

      observer.publish('trackolding-loaded');

      grapplerAsync(data);
    })
    .catch((err) => {
      logger.logError(err, {
        libraryName: 'trackolding',
        'service.name': data?.microsite?.name,
      });
    });
} catch (err) {
  logger.logError(err, {
    libraryName: 'trackolding',
    'service.name': data?.microsite?.name,
  });
}
