/* eslint-disable no-underscore-dangle */
import buildCookieObject from './buildCookieObject';
import serializeToString from './serialize';

export default class MultiValueCookie {
  constructor(initialValue) {
    this._modified = false;
    this._cookieObject = buildCookieObject(initialValue) || {};
  }

  getValue(key) {
    return this._cookieObject[key];
  }

  setValue(key, value) {
    this._cookieObject[key] = value;
    this._modified = true;
    return this;
  }

  deleteValue(key) {
    delete this._cookieObject[key];
    this._modified = true;
    return this;
  }

  clear() {
    this._cookieObject = {};
    this._modified = true;
    return this;
  }

  isModified() {
    return this._modified;
  }

  keys() {
    return Object.keys(this._cookieObject);
  }

  serializeToString() {
    return serializeToString(this._cookieObject);
  }
}
