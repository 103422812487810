import { initGrapplerListeners } from './grapplerEvent';

const initAsync = (data) => {
  const market =
    data.culture && data.culture.market ? data.culture.market : null;
  const componentName =
    data.microsite && data.microsite.name ? data.microsite.name : null;
  const environment = data.config && data.config.env ? data.config.env : null;
  initGrapplerListeners(environment, componentName, market);
};

export { initAsync };
