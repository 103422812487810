import * as constants from './constants';

export default (cookieString) => {
  if (!cookieString || typeof cookieString !== 'string') return null;
  const rawProps = cookieString.split(constants.PAIR_SEPARATOR);
  const props = {};
  rawProps.forEach((prop) => {
    if (prop.indexOf(constants.KEY_VALUE_SEPARATOR) !== -1) {
      const [key, value] = prop.split(constants.KEY_VALUE_SEPARATOR);
      props[key] = decodeURIComponent(value);
    } else {
      props[prop] = null;
    }
  });
  return props;
};
