import { FETCH_MECHANISM } from 'saddlebag-grappler';

import { BehaviouralTracker } from '../../BehaviouralTracker';
import { getPageGuid } from '../../pageGuid';
import { ImpressionMessage } from '../message';
import { buildCommonObject } from '../utils';
import { ValidationError } from '../validationError';

import { entryStates, ImpressionMap } from './impressionUtils';

import type { GUID } from '../../pageGuid';
import type { Common } from '../../types/message';

const processedImpressions = new ImpressionMap();

export const getProcessedImpressionElement = (id: string) =>
  processedImpressions.get(id);

export const clearAllProcessedImpressionElements = () =>
  processedImpressions.clear();

export const removeProcessedImpressionElement = (id: string) =>
  processedImpressions.delete(id);

const sendImpression = ({
  common,
  currentPageGuid,
}: {
  common: Common;
  currentPageGuid: GUID;
}) =>
  BehaviouralTracker.track(
    'element_impression',
    'user_behaviour_data_domain.ElementImpression',
    new ImpressionMessage({
      view_guid: currentPageGuid,
      common,
    }),
    FETCH_MECHANISM,
  );

export const impressionProcessor = (nodeList: NodeListOf<Element>) => {
  const flatArrayOfIds: string[] = [];
  const currentPageGuid = getPageGuid();
  if (currentPageGuid === undefined) return;
  for (const element of nodeList) {
    const id = element.getAttribute('data-tracking-element-id');
    if (id) {
      flatArrayOfIds.push(id);
      if (id && !processedImpressions.has(id)) {
        // to avoid a race condition when the interval next executes we want to
        // isolate an element that's in progress to prevent multiple events.
        processedImpressions.set(id, { state: entryStates.Processing });
        const common = buildCommonObject(element);
        sendImpression({ common, currentPageGuid })
          .then(({ guid }) =>
            // in the unlikely event the impression submission fails and we don't receive a guid
            // remove the id from the elementEntry map to be retried on next loop.
            guid
              ? processedImpressions.set(id, {
                  guid,
                  state: entryStates.Processed,
                })
              : removeProcessedImpressionElement(id),
          )
          .catch((e) => {
            // Impressions that have failed due to validation errors are not retried.
            // Instead they are set as invalid.
            if (e instanceof ValidationError) {
              processedImpressions.set(id, {
                state: entryStates.Invalid,
              });
            } else {
              removeProcessedImpressionElement(id);
            }
          });
      }
    }
  }
  // Elements that have already been previously registered and are then subsequently not found in the Scan are assumed
  // to have been removed from the page. Therefore we will drop them from our Map of processed impressions.
  processedImpressions.forEach((_, id) => {
    if (!flatArrayOfIds.includes(id)) {
      removeProcessedImpressionElement(id);
    }
  });
};
