import { logOperationalEvent } from 'saddlebag-logger';

import { MiniEventsTrackingSingleton } from './MiniEventsTrackingSingleton';
import { Exit } from './events/Exit';
import { Impression } from './events/Impression';
import { Interaction } from './events/Interaction';
import { setPageGuid } from './pageGuid';

import type {
  EventConfiguration,
  InternalEventConfiguration,
} from './configuration';
import type { GUID } from './pageGuid';

export class BehaviouralSDK {
  static configuration: InternalEventConfiguration['behavioural'] = {
    exitEnabled: false,
    impressionEnabled: false,
    interactionEnabled: false,
  };

  static init(configuration: EventConfiguration) {
    if ('enabled' in configuration.behavioural) {
      if (configuration.behavioural.enabled) {
        this.configuration.exitEnabled = true;
        this.configuration.impressionEnabled = true;
        this.configuration.interactionEnabled = true;
      } else {
        this.configuration.exitEnabled = false;
        this.configuration.impressionEnabled = false;
        this.configuration.interactionEnabled = false;
      }
    } else {
      this.configuration = configuration.behavioural;
    }
    if (
      this.configuration.interactionEnabled &&
      !this.configuration.impressionEnabled
    ) {
      throw new Error(
        'Interaction events require impression events to be enabled',
      );
    }
    this.configuration.batch = {
      impression: false, // default to false
      interaction: false, // default to false
      ...configuration.behavioural.batch,
    };
    logOperationalEvent({
      eventName: 'BehaviouralSDK_init',
      exitEnabled: this.configuration.exitEnabled.toString(),
      impressionEnabled: this.configuration.impressionEnabled.toString(),
      interactionEnabled: this.configuration.interactionEnabled.toString(),
      impressionBatched: this.configuration.batch.impression.toString(),
      interactionBatched: this.configuration.batch.interaction.toString(),
    });
    if (
      this.configuration.exitEnabled ||
      this.configuration.impressionEnabled
    ) {
      MiniEventsTrackingSingleton.init(configuration);
    }
    if (this.configuration.exitEnabled) {
      Exit.init();
    }
    if (this.configuration.interactionEnabled) {
      Interaction.init();
    }
  }

  static startPageTransition() {
    if (this.configuration.exitEnabled) {
      Exit.startPageTransition();
    }
    if (this.configuration.impressionEnabled) {
      Impression.startPageTransition();
    }
  }

  static stopPageTransition(pageViewGUID: GUID) {
    if (
      this.configuration.exitEnabled ||
      this.configuration.impressionEnabled
    ) {
      setPageGuid(pageViewGUID);
    }
    if (this.configuration.impressionEnabled) {
      Impression.stopPageTransition();
    }
  }
}
