/**
 * The modern module of trackolding is aimed at supporting Skyscanner's future tracking requirements.
 *
 * The apis provided by this module are client *only*. The apis are not expected to and should not be called on the server.
 *
 * The apis provided by this module should be consumed in applications that contain SPA style "soft navigations".
 * Applications with only traditional "hard navigations" do not require the apis provided here.
 * Instead, seek out the traditional method of integrating trackolding.
 *
 * The api in this module is centered around applications informing trackolding when soft navigations have occured.
 * @module
 */
import { BehaviouralSDK } from './BehaviouralSDK';

import type { EventConfiguration } from './configuration';
import type { GUID } from './pageGuid';

export type { EventConfiguration } from './configuration';
export type { Common } from './types/message';

/**
 * startPageTransition informs trackolding when the application has started to navigate to a new page.
 *
 * It is essential that a {@link startPageTransition} is paired with a {@link stopPageTransition} call.
 * Without this, the tracking functionality will not work.
 *
 * A toy example of how the {@link startPageTransition} and {@link stopPageTransition} work together is provided below.
 * For integration guidance, please see: [RESOURCE TO BE LINKED]
 *
 * @example
 * ```ts
 * useLocationEffect(async (location) => {
 *  console.log("🍎🍎🍎 Location changed", location);
 *  startPageTransition();
 *  const pageGuid = await getAsyncPageGuid();
 *  console.log("🍎🍎🍎 Application level sends view event!", pageGuid);
 *  stopPageTransition(pageGuid);
 * })
 * ```
 */
export const startPageTransition = () => {
  // Send events only on client
  if (typeof window !== 'undefined') {
    BehaviouralSDK.startPageTransition();
  }
};

/**
 * stopPageTransition informs trackolding when the application has finished a navigation to a new page.
 *
 * stopPageTransition api provides the ability for the application to inform trackolding of the page view GUID
 * for the new page that the application has just transitioned to.
 * Applications must provide this GUID or tracking functionality will not work.
 *
 * It is essential that a {@link stopPageTransition} is paired with a {@link startPageTransition} call.
 * Without this, the tracking functionality will not work.
 *
 * A toy example of how the {@link startPageTransition} and {@link stopPageTransition} work together is provided below.
 * For integration guidance, please see: [RESOURCE TO BE LINKED]
 *
 * @example
 * ```ts
 * useLocationEffect(async (location) => {
 *  console.log("🍎🍎🍎 Location changed", location);
 *  startPageTransition();
 *  const pageGuid = await getAsyncPageGuid();
 *  console.log("🍎🍎🍎 Application level sends view event!", pageGuid);
 *  stopPageTransition(pageGuid);
 * })
 * ```
 */
export const stopPageTransition = (newPageViewGUID: GUID) => {
  // Send events only on client
  if (typeof window !== 'undefined') {
    BehaviouralSDK.stopPageTransition(newPageViewGUID);
  }
};

/**
 * Initialises the tracking functionality, configuration can be provided to affect tracking behaviour.
 *
 * This must be called prior to {@link startPageTransition} and {@link stopPageTransition}.
 * It is expected to be called at the startup/bootstrap phase of the application.
 * It should not be called again within the lifetime of the application.
 */
export const init = (configuration: EventConfiguration) => {
  if (typeof window !== 'undefined') {
    BehaviouralSDK.init(configuration);
  }
};
