import type { GUID } from '../../pageGuid';

export const entryStates = {
  Processing: 0,
  Processed: 1,
  Invalid: 2,
} as const;

type EntryStatesKeys = keyof typeof entryStates;
type EntryStates = (typeof entryStates)[EntryStatesKeys];

// By using our own Class we avoid mocking the global Map which can be easily influenced by other files and their code.
export class ImpressionMap extends Map<
  string,
  { guid?: GUID; state: EntryStates }
> {}
